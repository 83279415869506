<template>
	<article class="d-front-page-custom">
		<FrontpageHeader class="mt-lg" v-bind="heroItem" />

		<BlockList
			v-if="contentElements && contentElements.length"
			class="relative mt-5xl"
			:block-list="contentElements"
		/>

		<FeedbackModule
			v-if="!hideFeedback"
			:feedback="feedback"
			class="mt-7xl"
		/>
	</article>
</template>

<script>
import FrontpageHeader from '~/components/main/FrontpageHeader';
import BlockList from '~/components/main/BlockList';
import FeedbackModule from '~/components/shared/FeedbackModule';

export default {
	name: 'MainFrontPageCustom',
	components: { FrontpageHeader, BlockList, FeedbackModule },
	inheritAttrs: false,

	props: {
		contentElements: {
			type: Array,
			default: () => [],
		},
		heroItem: {
			type: Object,
			default: () => ({}),
		},
		hideFeedback: {
			type: Boolean,
			default: false,
		},
		feedback: Object,
	},
};
</script>
