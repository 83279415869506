var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"c-frontpage-header relative"},[_c('LensFlare',{class:[
			'c-frontpage-header__lens-flare-desktop',
			'absolute max-w-layout-max',
			'transform -translate-x-1/2 -translate-y-1/2',
			'w-visual-screen hidden >=768:block',
		],attrs:{"id":"c-frontpage-header__flare","angle":18,"min-blur-amount":10,"max-blur-amount":10,"easing":(x) => x ** 2.5 * 1.3,"flares":[
			{
				size: 310,
				offset: -200,
				targetOffset: -1500,
				theme: 'secondary',
				opacity: 0.5,
			},
			{
				size: 475,
				offset: -50,
				targetOffset: -240,
				theme: 'primary',
				opacity: 1,
			},
			{
				size: 400,
				targetSize: 250,
				offset: 120,
				targetOffset: 50,
				theme: 'secondary',
				opacity: 0.8,
			},
			{
				size: 300,
				targetSize: 40,
				offset: -80,
				targetOffset: 800,
				theme: 'secondary',
				opacity: 0.8,
			},
			{
				size: 225,
				targetSize: -75,
				offset: -260,
				targetOffset: 1400,
				theme: 'secondary',
				opacity: 0.8,
			},
			{
				size: 300,
				targetSize: -250,
				offset: 300,
				targetOffset: 1750,
				theme: 'secondary',
				opacity: 0.5,
			},
		]}}),_vm._v(" "),_c('LensFlare',{class:[
			'c-frontpage-header__lens-flare-mobile',
			'absolute max-w-layout-max',
			'transform -translate-x-1/2 -translate-y-1/2',
			'w-visual-screen >=768:hidden',
		],attrs:{"id":"c-frontpage-header__mobile","angle":45,"min-blur-amount":15,"max-blur-amount":15,"flares":[
			{
				size: 400,
				offset: -350,
				targetOffset: -750,
				theme: 'secondary',
				opacity: 0.8,
			},
			{
				size: 650,
				targetSize: 450,
				offset: 300,
				targetOffset: 550,
				theme: 'primary',
				opacity: 1,
			},
			{
				size: 350,
				targetSize: 100,
				offset: 470,
				targetOffset: 1070,
				theme: 'secondary',
				opacity: 0.8,
			},
		]}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col >=768:flex-row gap-x-layout-gutter gap-y-2xl"},[_c('div',{class:[
				'flex-shrink-0 w-full',
				'>=768:w-6/12col >=1024:pl-xl >=1024:pr-5xl',
			]},[(_vm.media)?_c('figure',{staticClass:">=768:w-5/12col >=1024:w-full"},[_c('UmbracoImageExt',{staticClass:"w-full rounded-full overflow-hidden",attrs:{"image-class-names":"rounded-full overflow-hidden","widths":[343, 500, 700, 1000],"sizes":_vm.imageSizes({
							default: '92vw',
							768: '35vw',
						}),"alt":_vm.media.altText,"source-url":_vm.media.cropUrl,"source-width":_vm.media.width,"source-height":_vm.media.height,"aspect-ratio":1,"lazyload":false}})],1):_vm._e()]),_vm._v(" "),_c('div',{class:[
				'hidden >=768:flex',
				'flex-col justify-end gap-y-xl',
				'w-6/12col >=1024:w-5/12col pb-2xl',
			]},[_c('h1',{staticClass:"font-darker-grotesque font-bold text-h1-frontpage",domProps:{"textContent":_vm._s(_vm.title)}}),_vm._v(" "),(_vm.link)?_c('NuxtLinkExt',{staticClass:"flex items-center gap-x-sm group",attrs:{"to":_vm.link.url,"target":_vm.link.target}},[_c('div',{class:[
						'w-40 h-40 rounded-full bg-text text-white',
						'flex grow-0 shrink-0 justify-center items-center',
						'flex-shrink-0',
					]},[_c('SvgArrow',{staticClass:"w-20"})],1),_vm._v(" "),_c('span',{class:[
						'font-darker-grotesque font-bold',
						'text-button-xs uppercase',
						'duration-500 ease-smooth-out',
						'transform group-hover:translate-x-8',
					],domProps:{"textContent":_vm._s(_vm.link.name)}})]):_vm._e()],1),_vm._v(" "),_c('div',{class:['>=768:hidden']},[(_vm.link)?_c('NuxtLinkExt',{staticClass:"w-full",attrs:{"to":_vm.link.url,"target":_vm.link.target}},[_c('span',{class:[
						'inline-flex mr-sm',
						'w-40 h-40 rounded-full bg-text text-white',
					]},[_c('SvgArrow',{staticClass:"mx-auto w-20"})],1),_vm._v(" "),_c('h1',{class:[
						'inline',
						'font-darker-grotesque font-bold text-h1-frontpage',
					],domProps:{"textContent":_vm._s(_vm.title)}})]):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
			'hidden >=768:flex',
			'justify-between items-center gap-x-layout-gutter',
			'w-full py-20 bg-text mt-48',
		]},[_c('div',{class:[
				'font-darker-grotesque font-medium text-h3 text-white',
				'mb-6 ml-40',
			]},[_vm._v("\n\t\t\tHvad kan vi hjælpe dig med?\n\t\t")]),_vm._v(" "),_c('div',{staticClass:"relative w-6/12col pr-40 flex-shrink-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],class:[
					'w-full',
					'h-60 pl-xl pr-80 pb-4 appearance-none align-middle rounded-full',
					'font-darker-grotesque font-semibold text-input leading-1',
				],attrs:{"type":"search","aria-label":"søgefelt"},domProps:{"value":(_vm.searchText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => _vm.$router.push(_vm.searchUrl)).apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),_vm._v(" "),_c('NuxtLinkExt',{class:[
					'absolute top-1/2 right-80',
					'transform -translate-y-1/2',
					'h-22 rounded-26 text-text',
				],attrs:{"to":_vm.searchUrl,"aria-label":"Søg"}},[_c('SvgSearch',{staticClass:"w-full h-full"})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }