var render = function render(){var _vm=this,_c=_vm._self._c;return (
		(_vm.blocks && _vm.blocks.length) ||
		_vm.$scopedSlots.aside ||
		_vm.$scopedSlots.before ||
		_vm.$scopedSlots.after
	)?_c('div',{class:[
		'c-block-list w-full relative',
		{
			'grid grid-cols-5 >=1024:grid-cols-12 gap-x-layout-gutter':
				_vm.gridBased,
		},
	]},[(_vm.$scopedSlots.aside)?_c('div',{class:[
			'mb-xl >=1024:mb-0',
			'col-start-1 col-end-6 >=1024:col-end-4',
		]},[_vm._t("aside")],2):_vm._e(),_vm._v(" "),_c('div',{class:[
			'col-start-1 col-end-6',

			{
				'>=1024:col-start-4 >=1024:col-end-12':
					_vm.$scopedSlots.aside && !_vm.isWide,
				'>=1024:col-start-3 >=1024:col-end-11':
					!_vm.$scopedSlots.aside && !_vm.isWide,
				'>=1024:col-start-1 >=1024:col-end-13': _vm.isWide,
			},
		]},[(_vm.$scopedSlots.before)?_c('div',[_vm._t("before")],2):_vm._e(),_vm._v(" "),(_vm.blocks && _vm.blocks.length)?_c('LongReadContext',[_vm._l((_vm.blocks),function(block){return [(_vm.blockExists(block.alias))?_c(_vm.getBlockNameFromAlias(block.alias),_vm._b({key:block.key,tag:"Component",staticClass:"c-block-list__block"},'Component',{
						...block.content,
						wideLayout: _vm.isWide,
						includeInToc: block.includeInToc,
					},false)):(_vm.isDevelopment)?_c('BlockListPlaceholder',_vm._b({key:block.key,staticClass:"c-block-list__block"},'BlockListPlaceholder',block,false)):_vm._e()]})],2):_vm._e(),_vm._v(" "),(_vm.$scopedSlots.after)?_c('div',[_vm._t("after")],2):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }