<template>
	<header class="c-frontpage-header relative">
		<!-- Lens Flare Desktop -->
		<LensFlare
			id="c-frontpage-header__flare"
			:class="[
				'c-frontpage-header__lens-flare-desktop',
				'absolute max-w-layout-max',
				'transform -translate-x-1/2 -translate-y-1/2',
				'w-visual-screen hidden >=768:block',
			]"
			:angle="18"
			:min-blur-amount="10"
			:max-blur-amount="10"
			:easing="(x) => x ** 2.5 * 1.3"
			:flares="[
				{
					size: 310,
					offset: -200,
					targetOffset: -1500,
					theme: 'secondary',
					opacity: 0.5,
				},
				{
					size: 475,
					offset: -50,
					targetOffset: -240,
					theme: 'primary',
					opacity: 1,
				},
				{
					size: 400,
					targetSize: 250,
					offset: 120,
					targetOffset: 50,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 300,
					targetSize: 40,
					offset: -80,
					targetOffset: 800,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 225,
					targetSize: -75,
					offset: -260,
					targetOffset: 1400,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 300,
					targetSize: -250,
					offset: 300,
					targetOffset: 1750,
					theme: 'secondary',
					opacity: 0.5,
				},
			]"
		/>

		<!-- Lens Flare Mobile -->
		<LensFlare
			id="c-frontpage-header__mobile"
			:class="[
				'c-frontpage-header__lens-flare-mobile',
				'absolute max-w-layout-max',
				'transform -translate-x-1/2 -translate-y-1/2',
				'w-visual-screen >=768:hidden',
			]"
			:angle="45"
			:min-blur-amount="15"
			:max-blur-amount="15"
			:flares="[
				{
					size: 400,
					offset: -350,
					targetOffset: -750,
					theme: 'secondary',
					opacity: 0.8,
				},
				{
					size: 650,
					targetSize: 450,
					offset: 300,
					targetOffset: 550,
					theme: 'primary',
					opacity: 1,
				},
				{
					size: 350,
					targetSize: 100,
					offset: 470,
					targetOffset: 1070,
					theme: 'secondary',
					opacity: 0.8,
				},
			]"
		/>

		<div class="flex flex-col >=768:flex-row gap-x-layout-gutter gap-y-2xl">
			<!-- Image -->
			<div
				:class="[
					'flex-shrink-0 w-full',
					'>=768:w-6/12col >=1024:pl-xl >=1024:pr-5xl',
				]"
			>
				<figure v-if="media" class=">=768:w-5/12col >=1024:w-full">
					<UmbracoImageExt
						class="w-full rounded-full overflow-hidden"
						image-class-names="rounded-full overflow-hidden"
						:widths="[343, 500, 700, 1000]"
						:sizes="
							imageSizes({
								default: '92vw',
								768: '35vw',
							})
						"
						:alt="media.altText"
						:source-url="media.cropUrl"
						:source-width="media.width"
						:source-height="media.height"
						:aspect-ratio="1"
						:lazyload="false"
					/>
				</figure>
			</div>

			<!-- Content Desktop -->
			<div
				:class="[
					'hidden >=768:flex',
					'flex-col justify-end gap-y-xl',
					'w-6/12col >=1024:w-5/12col pb-2xl',
				]"
			>
				<h1
					class="font-darker-grotesque font-bold text-h1-frontpage"
					v-text="title"
				></h1>

				<NuxtLinkExt
					v-if="link"
					:to="link.url"
					:target="link.target"
					class="flex items-center gap-x-sm group"
				>
					<div
						:class="[
							'w-40 h-40 rounded-full bg-text text-white',
							'flex grow-0 shrink-0 justify-center items-center',
							'flex-shrink-0',
						]"
					>
						<SvgArrow class="w-20" />
					</div>

					<span
						:class="[
							'font-darker-grotesque font-bold',
							'text-button-xs uppercase',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
						]"
						v-text="link.name"
					></span>
				</NuxtLinkExt>
			</div>

			<!-- Content Mobile -->
			<div :class="['>=768:hidden']">
				<NuxtLinkExt
					v-if="link"
					:to="link.url"
					:target="link.target"
					class="w-full"
				>
					<span
						:class="[
							'inline-flex mr-sm',
							'w-40 h-40 rounded-full bg-text text-white',
						]"
					>
						<SvgArrow class="mx-auto w-20" />
					</span>

					<h1
						:class="[
							'inline',
							'font-darker-grotesque font-bold text-h1-frontpage',
						]"
						v-text="title"
					></h1>
				</NuxtLinkExt>
			</div>
		</div>

		<!-- Search box -->
		<div
			:class="[
				'hidden >=768:flex',
				'justify-between items-center gap-x-layout-gutter',
				'w-full py-20 bg-text mt-48',
			]"
		>
			<div
				:class="[
					'font-darker-grotesque font-medium text-h3 text-white',
					'mb-6 ml-40',
				]"
			>
				Hvad kan vi hjælpe dig med?
			</div>

			<div class="relative w-6/12col pr-40 flex-shrink-0">
				<input
					v-model="searchText"
					type="search"
					:class="[
						'w-full',
						'h-60 pl-xl pr-80 pb-4 appearance-none align-middle rounded-full',
						'font-darker-grotesque font-semibold text-input leading-1',
					]"
					aria-label="søgefelt"
					@keydown.enter="() => $router.push(searchUrl)"
				/>

				<NuxtLinkExt
					:to="searchUrl"
					:class="[
						'absolute top-1/2 right-80',
						'transform -translate-y-1/2',
						'h-22 rounded-26 text-text',
					]"
					aria-label="Søg"
				>
					<SvgSearch class="w-full h-full" />
				</NuxtLinkExt>
			</div>
		</div>
	</header>
</template>

<script>
import { imageSizes } from '@limbo-works/vue-umbraco-image';

import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgSearch from '~/assets/svgs/icon-search.svg?inline';
import LensFlare from '~/components/shared/LensFlare';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import globalEventBus from '~/assets/js/global-event-bus';

export default {
	name: 'FrontpageHeader',

	components: {
		SvgArrow,
		SvgSearch,
		LensFlare,
		UmbracoImageExt,
	},

	props: {
		title: String,
		media: Object,
		link: Object,
	},

	data() {
		const { searchPage } = this.$store.state.site || {};

		return {
			searchPage: searchPage || { url: '/da/sog/' },
			searchText: '',
		};
	},

	computed: {
		searchUrl() {
			return [this.searchPage?.url, this.searchText]
				.filter(Boolean)
				.join('?text=');
		},
	},

	watch: {
		searchText(newValue) {
			globalEventBus.$emit('globalSearch:textUpdate', newValue);
		},
	},

	mounted() {
		globalEventBus.$on('globalSearch:textUpdate', (searchText) => {
			if (this.searchText !== searchText) {
				this.searchText = searchText;
			}
		});
	},

	methods: {
		imageSizes,
	},
};
</script>

<style lang="postcss">
.c-frontpage-header__lens-flare-desktop {
	left: 40%;
	top: 63%;
}
.c-frontpage-header__lens-flare-mobile {
	left: 50%;
	top: 40%;
}
</style>
