<template>
	<div
		v-if="
			(blocks && blocks.length) ||
			$scopedSlots.aside ||
			$scopedSlots.before ||
			$scopedSlots.after
		"
		:class="[
			'c-block-list w-full relative',
			{
				'grid grid-cols-5 >=1024:grid-cols-12 gap-x-layout-gutter':
					gridBased,
			},
		]"
	>
		<div
			v-if="$scopedSlots.aside"
			:class="[
				'mb-xl >=1024:mb-0',
				'col-start-1 col-end-6 >=1024:col-end-4',
			]"
		>
			<slot name="aside"></slot>
		</div>

		<div
			:class="[
				'col-start-1 col-end-6',

				{
					'>=1024:col-start-4 >=1024:col-end-12':
						$scopedSlots.aside && !isWide,
					'>=1024:col-start-3 >=1024:col-end-11':
						!$scopedSlots.aside && !isWide,
					'>=1024:col-start-1 >=1024:col-end-13': isWide,
				},
			]"
		>
			<div v-if="$scopedSlots.before">
				<slot name="before"></slot>
			</div>

			<LongReadContext v-if="blocks && blocks.length">
				<template v-for="block in blocks">
					<Component
						:is="getBlockNameFromAlias(block.alias)"
						v-if="blockExists(block.alias)"
						:key="block.key"
						class="c-block-list__block"
						v-bind="{
							...block.content,
							wideLayout: isWide,
							includeInToc: block.includeInToc,
						}"
					/>

					<BlockListPlaceholder
						v-else-if="isDevelopment"
						:key="block.key"
						class="c-block-list__block"
						v-bind="block"
					/>
				</template>
			</LongReadContext>

			<div v-if="$scopedSlots.after">
				<slot name="after"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import BlockListPlaceholder from '~/components/shared/BlockList/blocks/BlockListPlaceholder.vue';
import {
	getBlockNameFromAlias,
	getBlocksBySolution,
} from '~/assets/js/block-lists';
import LongReadContext from '~/components/shared/LongReadContext.vue';

const blocks = Object.assign(
	getBlocksBySolution('shared'),
	getBlocksBySolution('main')
);
const blockNames = Object.keys(blocks);

export default {
	name: 'MainBlockList',

	components: {
		BlockListPlaceholder,
		LongReadContext,

		...blocks,
	},

	props: {
		blockList: {
			type: Array,
			default: () => [],
		},
		gridBased: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		const { template } = this.$store.state;
		return {
			isDevelopment: process.env.NODE_ENV === 'development',
			isWide: template === 'FrontPage' || template === 'OverviewList',
		};
	},

	computed: {
		blocks() {
			return (this.blockList ?? []).filter((block) => {
				if (!block || block?.content?.deactivated === true) {
					return false;
				}

				if (block?.alias && block?.content && block?.key) {
					return true;
				} else if (process.env.NODE_ENV === 'development') {
					console.warn(
						`[BlockList] ${block.alias} has missing or faulty data:`,
						block
					);
				}

				return false;
			});
		},
	},

	methods: {
		getBlockNameFromAlias,

		hasSmallerSpacing(alias) {
			return !alias;
		},

		blockExists(alias) {
			return blockNames.includes(getBlockNameFromAlias(alias));
		},
	},
};
</script>

<style lang="postcss">
:where(.c-block-list__block:not(:first-child)) {
	@apply mt-5xl;
}

:where(.c-block-list-rich-text-editor-custom
		+ .c-block-list-cta-button-custom) {
	@apply mt-xl;
}
</style>
